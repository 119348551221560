import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, TrailguidePlugin } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><strong parentName="p">{`Were you ever glad about a tip for a great MTB singletrack? Or were you
despereately in need for a good information? Share your trails, be part of
the Trailguide community and also win cool prizes!`}</strong></p>
    <Image src="news/uploadtrails_3.jpg" mdxType="Image" />
    <p>{`Finding good singletracks is really not easy. There are loads of services
where you can find GPS tracks, but they often send you down on long parts of
boring gravel road. Your hard climb did not get rewarded with a nice descent.`}</p>
    <br />
    <p>{`Trailguide is focused on trail biking. Narrow trails up to 1m width, usually
called singletracks or singletrails. This focus allows you to be sure to find
good tracks for your ride.`}</p>
    <Image src="news/uploadtrails_6.jpg" mdxType="Image" />
    <p>{`Of course the information does not provide itself automatically, but you can
thank the growing Trailguide community which together share their experiences.
For you it means uploading a few trails you know, and as a reward it means that
you will find a huge amount great singletracks around the world. Think of it
as a great investment. Invest a little and get a lot back. Review a trail,
report conditions, share trails with your friends. All this information helps
you and others to find great rides.`}</p>
    <Image src="news/uploadtrails_5.jpg" mdxType="Image" />
    <p>{`Uploading a track is very easy! Almost every GPS device or app can record the
data as a .gpx file. Open `}<a parentName="p" {...{
        "href": "http://www.trailguide.net"
      }}>{`trailguide.net`}</a>{`
on your computer and drag and drop the file into the "Add trail" window.
If needed, crop your loop or ride to cover only the singletrack parts using
the slider below the map. Add additional info and thats it! You uploaded your
track :-)`}</p>
    <Image src="news/uploadtrails_7.jpg" mdxType="Image" />
    <p>{`With everyone contributing a little bit, Trailguide gets better and better
everyday. Therefore we want to thank you and also want to reward the
contributors of the Trailguide community!`}</p>
    <Image src="news/uploadtrails_2.jpg" mdxType="Image" />
    <p>{`For everyone who uploaded trails, we are going to draw two nights in the
`}<a parentName="p" {...{
        "href": "https://www.hotelalpenblick.ch"
      }}>{`Hotel Alpenblick`}</a>{` including lift tickets for
two days in the awesome `}<a parentName="p" {...{
        "href": "https://www.aletscharena.ch"
      }}>{`Aletsch Arena`}</a>{`.`}</p>
    <Image src="news/uploadtrails_4.jpg" mdxType="Image" />
    <p>{`All pictures you see here were taken in the stunning landscape of the
`}<a parentName="p" {...{
        "href": "https://www.aletscharena.ch"
      }}>{`Aletsch Arena`}</a>{` with the longest glacier of the
alps. You can find the MTB destination on
`}<a parentName="p" {...{
        "href": "/destination/switzerland/aletsch"
      }}>{`Trailguide`}</a>{`.`}</p>
    <TrailguidePlugin content="lat=46.3792&lng=8.0648&coverage=5" mdxType="TrailguidePlugin" />
    <h4>{`Trail Map of the `}<a parentName="h4" {...{
        "href": "https://www.aletscharena.ch"
      }}>{`Aletsch Arena`}</a></h4>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      